@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'cervo-medium';
        src: url('./assets/fonts/cervo-medium.ttf') format('truetype');
    }
}
